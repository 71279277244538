import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/fontawesome";

import "./index.css";

const section = document.getElementsByClassName("section");
const buttons = document.getElementsByClassName("op-button");
const endo = document.getElementById("endometriosis");
const endo_sections = document.getElementById("endo-sections");
const bladder = document.getElementById("bladder");
const bladder_sections = document.getElementById("bladder-sections");
const periods = document.getElementById("periods");
const period_sections = document.getElementById("period-sections");
const dropDowns = document.getElementsByClassName("btn-drp-dn");

function close(icon, links) {
  links.classList.add("hidden");
  icon.classList.remove("fa-chevron-up");
  icon.classList.add("fa-chevron-down");
}

function open(icon, links) {
  links.classList.remove("hidden");
  icon.classList.remove("fa-chevron-down");
  icon.classList.add("fa-chevron-up");
}

function showOthers(i) {
  for (let x = 0; x < section.length; x++) {
    if (x !== i) {
      const icon = section[x].getElementsByClassName("expand")[0];
      section[x].classList.remove("hidden");
      const links = section[x].nextElementSibling;
      close(icon, links);
    }
  }
}

function hideOthers(i) {
  for (let x = 0; x < section.length; x++) {
    if (x !== i) {
      section[x].classList.add("hidden");
    }
  }
}

function sectionClicked(section) {
  section.classList.add("text-white");
  section.classList.add("bg-dark-blue");
  section.classList.remove("text-dark-blue");
  section.classList.remove("bg-white");
}

function sectionUnclicked(section) {
  section.classList.remove("text-white");
  section.classList.remove("bg-dark-blue");
  section.classList.add("text-dark-blue");
  section.classList.add("bg-white");
}

function showLinks(links) {
  links.classList.add("flex");
  links.classList.remove("hidden");
  links.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
}

function hideLinks(links) {
  links.classList.remove("flex");
  links.classList.add("hidden");
}

for (let i = 0; i < section.length; i++) {
  section[i].addEventListener("click", (e) => {
    const icon = e.currentTarget.getElementsByClassName("expand")[0];
    const links = icon.parentElement.nextElementSibling;

    if (icon.classList.contains("fa-chevron-up")) {
      close(icon, links);
      showOthers(i);
      if (i !== 0) {
        section[i].classList.add("mt-7");
      }
    } else {
      hideOthers(i);
      section[i].classList.remove("mt-7");
      open(icon, links);
    }
  });
}

endo.addEventListener("click", (e) => {
  if (endo.classList.contains("text-white")) {
    sectionUnclicked(endo);
    hideLinks(endo_sections);
  } else {
    for (let q = 0; q < buttons.length; q++) {
      if (buttons[q].classList.contains("text-white")) {
        sectionUnclicked(buttons[q]);
      }
      if (period_sections.classList.contains("flex")) {
        hideLinks(period_sections);
      } else if (bladder_sections.classList.contains("flex")) {
        hideLinks(bladder_sections);
      }
    }
    sectionClicked(endo);
    showLinks(endo_sections);
  }
});

bladder.addEventListener("click", (e) => {
  if (bladder.classList.contains("text-white")) {
    sectionUnclicked(bladder);
    hideLinks(bladder_sections);
  } else {
    for (let q = 0; q < buttons.length; q++) {
      if (buttons[q].classList.contains("text-white")) {
        sectionUnclicked(buttons[q]);
      }
      if (period_sections.classList.contains("flex")) {
        hideLinks(period_sections);
      } else if (endo_sections.classList.contains("flex")) {
        hideLinks(endo_sections);
      }
    }
    sectionClicked(bladder);
    showLinks(bladder_sections);
  }
});

periods.addEventListener("click", (e) => {
  if (periods.classList.contains("text-white")) {
    sectionUnclicked(periods);
    hideLinks(period_sections);
  } else {
    for (let q = 0; q < buttons.length; q++) {
      if (buttons[q].classList.contains("text-white")) {
        sectionUnclicked(buttons[q]);
      }
      if (endo_sections.classList.contains("flex")) {
        hideLinks(endo_sections);
      } else if (bladder_sections.classList.contains("flex")) {
        hideLinks(bladder_sections);
      }
    }
    sectionClicked(periods);
    showLinks(period_sections);
  }
});

for (let i = 0; i < dropDowns.length; i++) {
  const dropButton = dropDowns[i].parentElement.children[0];
  dropButton.addEventListener("click", (e) => {
    if (dropButton.children[0].classList.contains("fa-chevron-up")) {
      dropButton.children[0].classList.remove("fa-chevron-up");
      dropButton.children[0].classList.add("fa-chevron-down");
      dropDowns[i].classList.add("max-h-0");
      dropDowns[i].classList.remove("max-h-96");
    } else {
      for (let x = 0; x < dropDowns.length; x++) {
        const testButton = dropDowns[x].parentElement.children[0];
        console.log(testButton);
        if (testButton.children[0].classList.contains("fa-chevron-up")) {
          console.log("found match");
          testButton.children[0].classList.remove("fa-chevron-up");
          testButton.children[0].classList.add("fa-chevron-down");
          dropDowns[x].classList.add("max-h-0");
          dropDowns[x].classList.remove("max-h-96");
        }
      }
      dropButton.children[0].classList.remove("fa-chevron-down");
      dropButton.children[0].classList.add("fa-chevron-up");
      dropDowns[i].classList.remove("max-h-0");
      dropDowns[i].classList.add("max-h-96");
    }
  });
}

const contact_click = document.getElementById("contact_click");
const contact_box = document.getElementById("contact_box");
contact_click.addEventListener("click", (e) => {
  contact_box.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
});

const home_click = document.getElementById("home_click");
const home_box = document.getElementById("home_box");
home_click.addEventListener("click", (e) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
});
